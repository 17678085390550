import React from 'react'
import './App.css'

function App () {
  return (
    <div className='App'> Learn react </div>
  )
}

export default App
